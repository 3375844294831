jQuery(document).ready(function ($) {
  var body = $('body');

  $(window).scroll(function (event) {
    var scroll = $(window).scrollTop();
    //console.log(scroll);
    if (scroll > 94) {
      body.addClass('sticky-header');
    } else {
      body.removeClass('sticky-header');
    }
  });

  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    var scroll_distance = $($.attr(this, 'href')).offset().top;
    if (window.matchMedia("(min-width: 1024px)").matches) {
      scroll_distance = scroll_distance - 135
    }

    $('html, body').animate({
      scrollTop: scroll_distance
    }, 500);
  });
});

